<template>
  <!-- TODO: THIS HAS BEEN DECOMMISSIONED -->
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />
      <h1 class="text-xl  text-left font-extrabold ml-4 mt-3 mr-8">
        {{ $route.params.id ? "Edit User" : "New User" }}
      </h1>
    </div>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class=" mx-3">
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <Card class="mt-6 p-5">
          <p class="font-bold text-lg text-darkPurple">
            User Details
          </p>
          <image-upload
            @file="user.photo = $event"
            :url="url"
            :photo="user.photo"
            :name="`${user.fname} ${user.lname}`"
          />
          <div class="grid grid-cols-12 gap-7 mt-6">
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="First name"
                :rules="['required']"
                v-model="user.fname"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                label="Middle name"
                v-model="user.mname"
                variant="w-full"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                label="Last name"
                :rules="['required']"
                v-model="user.lname"
                variant="w-full"
              />
            </div>
            <div v-if="!user.isEmployee && $route.params.id" class="col-span-4">
              <div class="text-sm mb-1 text-jet">
                Work Email
              </div>
              <div
                class="wrapper disabled"
                v-tooltip.bottom-center="user.email"
              >
                {{ user.email }}
              </div>
            </div>
            <div v-else class="col-span-4">
              <c-text
                placeholder="--Enter--"
                label="Work Email"
                :rules="EmailRule"
                v-model="user.email"
                variant="w-full"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="--Enter--"
                variant="w-full"
                label="Phone Number"
                type="tel"
                v-model="user.phoneNo"
              />
            </div>
          </div>
        </Card>
        <Card class="mb-6 p-5">
          <p class="font-bold text-lg text-darkPurple">
            Function and Role
          </p>
          <div class="grid grid-cols-12 gap-7 mt-6">
            <div class="col-span-4">
              <c-select
                placeholder="--Select--"
                variant="w-full"
                label="Role"
                :rules="['required']"
                :options="roleSelect"
                v-model="user.role"
              />
            </div>
            <div class="col-span-4">
              <c-select
                placeholder="--Select--"
                variant="w-full"
                label="Functions"
                :options="filteredFunctions"
                v-model="user.functionId"
              />
            </div>
            <div class="col-span-4">
              <c-select
                placeholder="--Select--"
                variant="w-full"
                label="Designation"
                :options="setDesignation"
                v-model="user.designation"
              />
            </div>
            <div class="col-span-4">
              <c-select
                placeholder="--Select--"
                variant="w-full"
                :options="allOrgUsers"
                label="Reporting Line"
                v-model="user.reportingTo"
              />
            </div>
            <div class="col-span-4">
              <c-text
                placeholder="Automatically Loaded from Selected Function"
                disabled
                label="Job Level"
                variant="w-full"
                v-model="setLevel.name"
              />
            </div>
          </div>
          <div class="mt-20">
            <checkbox
              v-model="user.isEmployee"
              label="Add this user data to employee database"
              :disabled="typeof $route.params.id !== 'undefined' ? true : false"
            />
          </div>
        </Card>
        <div class="px-3">
          <div class="flex mt-4">
            <Button
              class="bg-dynamicBackBtn text-white buttons"
              :style="
                `${
                  disableBtn
                    ? 'background-color : rgba(241, 90, 41, 0.5); cursor: progress'
                    : ``
                }`
              "
              @click="
                $route.params.id
                  ? handleSubmit(updateUser)
                  : handleSubmit(submit)
              "
              :disabled="disableBtn"
            >
              Save
            </Button>
            <Button @click="cancel" class="buttons">
              Cancel
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import { clone } from "lodash";
import Card from "@/components/Card";
import imageUpload from "@/components/imageUpload";

export default {
  components: {
    BackButton,
    imageUpload,
    ValidationObserver,
    Card,
    CText,
    CSelect,
    Checkbox
  },
  data() {
    return {
      disableBtn: false,
      bgButton: "bg-flame",
      url: process.env.VUE_APP_IMAGE_UPLOAD,
      roleSelect: [],
      loading: true,
      allOrgUsers: [],
      levelName: "",
      EmailRule: [
        "required",
        {
          name: "email",
          rule: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        },
        {
          name: "available",
          rule: v => this.handleEmail(v)
        }
      ],
      user: {
        photo: null,
        fname: null,
        mname: null,
        lname: null,
        email: null,
        phoneNo: null,
        role: null,
        functionId: null,
        designation: null,
        reportingTo: null,
        level: null,
        isEmployee: false
      },
      employeeBasic: {
        basic: {
          photo: null,
          fname: "",
          lname: "",
          mname: "",
          dob: null,
          gender: "",
          email: "",
          alternateEmail: "",
          phoneNo: "",
          alternatePhoneNo: "",
          nokFname: "",
          nokLname: "",
          nokMname: "",
          nokEmail: "",
          nokAddress: "",
          nokRelationship: "",
          nokPhone: "",
          address: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          iso2: "",
          dialCode: "",
          functionId: "",
          level: "",
          designation: "",
          reportingTo: "",
          hrRole: ""
        }
      }
    };
  },
  methods: {
    async updateUser() {
      this.disableBtn = true;
      if (this.user.reportingTo === "null") {
        this.user.reportingTo = null;
      }
      const {
        photo,
        fname,
        mname,
        lname,
        email,
        phoneNo,
        role,
        functionId,
        designation,
        reportingTo,
        level,
        isEmployee
      } = this.user;
      const payload = {
        photo,
        fname,
        mname,
        lname,
        email,
        phoneNo,
        role: { appSlug: "humanar", roleId: role },
        functionId,
        designation,
        reportingTo,
        level,
        isEmployee
      };
      try {
        await this.$_updateUser({
          payload,
          userId: this.$route.params.id
        });
        this.$router.push({
          name: "UsersAndGroups",
          query: { currentTab: "Admins" }
        });
        this.$toasted.success("Admin updated successfully", { duration: 3000 });
        this.disableBtn = false;
      } catch (error) {
        this.$toasted.error("Admin not updated successfully", {
          duration: 3000
        });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
        throw new Error(error);
      }
    },
    submit() {
      // check if user was created also as employee
      if (this.user.isEmployee) {
        this.addEmployee();
      }

      if (this.user.reportingTo === "null") {
        this.user.reportingTo = null;
      }
      this.user.orgId = this.$orgId;
      this.user.level = this.setLevel.id;
      this.user.role = {
        appSlug: "humanar",
        roleId: this.user.role
      };
      const payLoad = this.user;
      this.disableBtn = true;
      this.bgButton = "bg-fadeFlame";
      this.$_createUser(payLoad)
        .then(result => {
          this.$toasted.success(`${result.data.message}`, { duration: 3000 });
          this.$router.push({
            name: "UsersAndGroups",
            query: { currentTab: "Users" }
          });
          this.disableBtn = false;
          this.bgButton = "bg-flame";
        })
        .catch(() => {
          this.$toasted.error("User cannot be created", { duration: 3000 });
          this.disableBtn = false;
          this.bgButton = "bg-flame";
        });
    },

    addEmployee() {
      this.employeeBasic.basic.photo = this.user.photo;
      this.employeeBasic.basic.fname = this.user.fname;
      this.employeeBasic.basic.mname = this.user.mname;
      this.employeeBasic.basic.lname = this.user.lname;
      this.employeeBasic.basic.email = this.user.email;
      this.employeeBasic.basic.phoneNo = this.user.phoneNo;
      this.employeeBasic.basic.createdBy = this.$AuthUser.id;
      this.employeeBasic.basic.orgId = this.$AuthUser.orgId;
      this.employeeBasic.basic.containsBio = true;
      this.employeeBasic.basic.functionId = this.user.functionId;
      this.employeeBasic.basic.reportingTo = this.user.reportingTo;
      this.employeeBasic.basic.hrRole = this.user.role;
      this.employeeBasic.basic.designation = this.user.designation;
      this.employeeBasic.basic.level = this.user.level;

      this.$_createEmployee(this.employeeBasic).then(res => res);
    },

    async handleEmail(value) {
      if (this.$route.params.id === "") {
        const result = await this.$_checkEmailExist(value);
        if (!this.editMode) {
          if (result.data.emailExists) {
            return "Email already exists";
          }
          return true;
        }
      }
      return true;
    },
    async fetchPeople(payload) {
      try {
        const { data } = await this.$_getAllOrgUsersWithOptions(payload);
        const allOrgUsers = data.users.map(item => ({
          id: item.id,
          name: `${item.fname} ${item.lname}`
        }));
        allOrgUsers.push({ name: "Nil", id: "null" });
        this.allOrgUsers = allOrgUsers;
        this.loading = false;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getDesignations() {
      try {
        const response = await this.$_getDesignations("");
        this.designationData = clone(response.data.designations);
      } catch (error) {
        throw new Error(error);
      }
    },
    async getRoles() {
      try {
        const response = await this.$_NewGetOrgRoles("");
        const orgRoles = response.data.roles;
        this.roleSelect = orgRoles.map(v => ({ id: v.id, name: v.name }));
      } catch (err) {
        throw new Error(err);
      }
    },

    getOneUser() {
      this.$_getAOneOrgUserWithOptions(this.$route.params.id).then(
        ({ data }) => {
          this.user = data.user;
          this.user.role = this.user.roles.humanar.roleId;
        }
      );
    },
    reset() {
      this.user = {
        photo: null,
        fname: null,
        mname: null,
        lname: null,
        email: null,
        phoneNo: null,
        role: null,
        functionId: null,
        designation: null,
        reportingTo: null,
        level: null,
        isEmployee: null
      };
    },
    cancel() {
      if (this.$route.params.id) {
        this.$router.push({
          name: "UsersAndGroups",
          query: { currentTab: "Users" }
        });
      } else {
        this.reset();
        this.$router.push({
          name: "UsersAndGroups",
          query: { currentTab: "Users" }
        });
      }
    }
  },
  computed: {
    filteredFunctions() {
      return this.$store.state.org.getAllFunctions;
    },
    setDesignation() {
      const findDesignation = this.$store.state.org.getAllFunctions.find(
        item => item.id === this.user.functionId
      );
      if (findDesignation) {
        return findDesignation.designations;
      }

      return [];
    },
    setLevel() {
      let result;
      if (this.setDesignation.length > 0) {
        result = this.setDesignation.find(
          item => item.id === this.user.designation
        );
      }

      return result ? result.level : "";
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getOneUser();
    }
    this.$store.dispatch("org/getFunction");
    this.getDesignations();
    this.getRoles();
    this.fetchPeople("");
  }
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
</style>
